<template>
  <div class="content">
    <PageHeader title="Relatório de Vendas" />
    <div class="page-content container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <form class="form-inline" @submit.prevent="fetch">
                <select-input
                  label="name"
                  v-model="form.account_id"
                  :reduce="(field) => field.id"
                  :options="customers"
                  :clearable="true"
                  :placeholder="$t('generic-str.customer')"
                  :class="'mr-sm-2'"
                />
                <date-range-picker
                  class="form-control mr-sm-2"
                  value
                  :options="options"
                  @change="changeDate"
                />
                <button type="submit" class="btn btn-primary">{{$t('generic-str.filter')}}</button>
              </form>
            </div>
            <div v-if="filtered" class="card-body p-0">
              <div v-if="fetched" class="table-responsive">
                <table
                  v-if="Object.keys(insights).length"
                  class="table table-striped"
                >
                  <thead>
                    <tr>
                      <th scope="col">{{$tc('generic-str.acc', 1)}}</th>
                      <th scope="col">Executivo</th>
                      <th scope="col">Cadastrado em:</th>
                      <th scope="col">Volume Tarifado</th>
                      <th scope="col">% Volumetria</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(insight, id) in insights" :key="id">
                      <td>{{ insight.account_name }}</td>
                      <td>
                        {{
                          insight.salesman ? insight.salesman.name : 'Nenhum'
                        }}
                      </td>
                      <td>
                        {{ insight.created_at | date('DD/MM/YYYY HH:mm') }}
                      </td>
                      <td>{{ insight.volumetry }}</td>
                      <td>
                        {{ Math.round((insight.volumetry * 100) / total) }}
                      </td>
                      <td>
                        <span
                          v-if="insight.balanceSheet > 0"
                          class="badge badge-pill badge-success"
                        >
                          {{ insight.balanceSheet }}%
                          <i
                            class="
                              zmdi zmdi-trending-up
                              font-size-14
                              text-white
                            "
                          ></i>
                        </span>
                        <span
                          v-else
                          class="badge badge-pill badge-danger"
                        >
                          {{ insight.balanceSheet }}%
                          <i
                            class="
                              zmdi zmdi-trending-down
                              font-size-14
                              text-white
                            "
                          ></i>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div v-else class="text-center table-placeholder">
                  <i class="fas fa-envelope font-size-80"></i>
                  <h5 class="card-title m-t-20">Nenhuma mensagem encontrada</h5>
                </div>
              </div>
              <div v-else class="static qt-block-ui" style="padding: 120px" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment';
import CustomerService from '@/services/customer.service';
import SmsService from '@/services/admin/sms.service';
import PageHeader from '@/components/PageHeader.vue';
import SelectInput from '@/components/SelectInput.vue';

export default {
  name: 'SmsDashboard',
  components: {
    PageHeader,
    SelectInput,
  },
  data() {
    return {
      fetched: false,
      options: {
        timePicker: false,
        autoApply: true,
        className: 'form-control',
        maxSpan: {
          days: 31,
        },
        locale: {
          format: 'DD/MM/YYYY',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Otu',
            'Nov',
            'Dez',
          ],
        },
      },
      filtered: false,
      customers: [],
      insights: [],
      total: 0,
      beginDate: '',
      endDate: '',
      form: {
        account_id: '',
        created_at: {
          gte: '',
          lte: '',
        },
      },
    };
  },
  created() {
    this.fetchCustomers();
  },
  methods: {
    fetchCustomers() {
      CustomerService.getCustomers().then(
        (customers) => {
          this.customers = customers;
          this.customers.push(this.$store.state.account);
        },
        (error) => {
          this.content = error;
        },
      );
    },
    fetch() {
      if (!this.filtered) this.filtered = true;
      this.fetched = false;
      this.total = 0;
      SmsService.getSales(this.form).then(
        (insights) => {
          this.insights = insights;
          Object.values(insights).forEach((insight, i) => {
            this.total += insight.volumetry;
            insight.balanceSheet = Math.round(
              (insight.volumetry * 100) / insight.previous_period - 100,
            );
          });
          this.fetched = true;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    changeDate(date) {
      if (date) {
        this.beginDate = moment(date[0], 'DD/MM/YYYY').format(
          'YYYY-MM-DD 00:00:00',
        );
        this.endDate = moment(date[1], 'DD/MM/YYYY').format(
          'YYYY-MM-DD 23:59:59',
        );
      } else {
        this.beginDate = moment().format('YYYY-MM-DD 00:00:00');
        this.endDate = moment().format('YYYY-MM-DD 23:59:59');
      }
      this.form.created_at.gte = this.beginDate;
      this.form.created_at.lte = this.endDate;
    },
    addFilter() {
      this.filters.push({
        field: '',
        values: null,
      });
    },
    removeFilter(n) {
      this.filters.splice(n, 1);
      // this.fetchAll();
    },
  },
};
</script>
